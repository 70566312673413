<template>
    <div class="login">
        <div class="ros-logo">
            <img src="@/assets/main_logo.png" alt="">
        </div>
        <div class="notice">
            <p style="font-size:24px;">ברוכים השבים</p>
            <p style="font-size:18px; color:var(--light)">קולג' בר</p>
        </div>

        <el-input prefix-icon="el-icon-user" placeholder="כתובת אימייל" v-model="login.email" type="email"></el-input>
        <el-input prefix-icon="el-icon-lock" placeholder="סיסמה" v-model="login.password" type="password"></el-input>

        <el-button type="success" style="width:100%; height:50px; margin-top: 35px;" @click="log_in">התחבר</el-button>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectAuth } from '../../firebase/config'
import router from '../../router'
import Swal from 'sweetalert2'
export default {
setup(){
    const login = ref({
        email: '',
        password: ''
    })
    
    const log_in = async () => {
        projectAuth.signInWithEmailAndPassword(login.value.email, login.value.password)
        .then(res => {
            if(res){
                router.replace('/')
            }
        })
        .catch(err => {
            var err_msg = '';
            if(err.code == "auth/wrong-password"){
                err_msg = 'סיסמה שגויה'
            }
            if(err.code == "auth/invalid-email"){
                err_msg = 'כתובת האימייל איננה תקנית'
            }
            console.log(err);
            Swal.fire({
                icon:'error',
                title: 'שגיאה',
                text: err_msg
            })
        })
    }

    return{
        login, log_in
    }
}
}
</script>

<style scoped>
.login{
    width: 100%;
    max-width: 375px;
    height: 100%;
    margin: auto;
    padding: 5px;
    overflow:hidden
}
.ros-logo{
    margin-top: 50px;
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
}
.ros-logo img{
    max-width: 90%;
    max-height: 90%;
}
.notice{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--yellow);
    margin-bottom: 50px;
    flex-shrink: 0;
}
::v-deep(.el-input__inner){
    text-align: center;
    font-size: 18px;
    width: 100%;
    margin-top: 5px;
}
</style>